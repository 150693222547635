import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import Seo from "../components/SEO"
import Layout from "../components/Layout"
import AuthorBox from "../components/AuthorBox"
import ShareArticle from "../components/ShareArticle"
import SectionTitle from "../components/SectionTitle"
import RelatedArticles from "../components/RelatedArticles"
import DownloadAppSection from "../components/DownloadAppSection"
import { LayoutContainer, Main } from "../components/UI"
import { capitalizeWord } from "../utils"

const BlogPostTemplate = ({ data, location }) => {
  const {
    datoCmsBlog: {
      title,
      slug,
      heroImage,
      body,
      author,
      seo,
      meta,
      noIndex,
      timeToRead,
    },
    allDatoCmsBlog: { nodes: relatedArticles },
  } = data
  const canonicalUrl = `https://thetoucan.app${location.pathname}`
  return (
    <Layout isBlogPage>
      <Seo
        title={seo?.title || capitalizeWord(title)}
        description={seo?.description}
        imageUrl={seo?.image?.url}
        twitterCard={seo?.twitterCard}
        noIndex={noIndex}
        canonicalUrl={canonicalUrl}
      />
      <Main className="bg-white pb-14 font-spartan md:pb-16 lg:pb-32">
        <div className="mb-[28px] rounded-b-[50px] bg-header-cta pt-[100px] pb-[18px] md:mb-[80px] md:pt-[120px] md:pb-[50px]">
          <LayoutContainer>
            <h1 className="custom-text-shadow text-center font-spartan text-2xl font-bold leading-[52px] text-white md:text-left md:text-6xl">
              The word from the bird.
            </h1>
          </LayoutContainer>
        </div>
        <div className="mx-auto max-w-screen-2xl px-6 lg:px-20 xl:px-20">
          <div className="custom-grid grid-area lg:grid-area-lg">
            <AuthorBox
              meta={meta}
              author={author}
              title={title}
              timeToRead={timeToRead}
              className="author-box lg:mr-[10rem]"
            />
            <GatsbyImage
              image={heroImage.gatsbyImageData}
              alt={heroImage.alt}
              className="hero-image-box mb-8 max-h-[350px] rounded-2xl"
            />
            <article className="dato-cms-renderer structured-text article-box mt-9 mb-10 md:mb-4">
              <StructuredText
                data={body}
                renderBlock={({ record }) => {
                  switch (record.__typename) {
                    case "DatoCmsInlineImageBlock":
                      return (
                        <GatsbyImage
                          image={record.image.gatsbyImageData}
                          alt={record.image.alt}
                          className="my-6"
                        />
                      )
                    default:
                      return null
                  }
                }}
                customRules={[
                  renderRule(isParagraph, ({ children, key }) => {
                    if (children[0].length === 0) return <br />
                    return (
                      <p key={key} className="my-4">
                        {children}
                      </p>
                    )
                  }),
                ]}
              />
            </article>
            <ShareArticle
              className="share-article-box mb-20 md:mb-16"
              slug={slug}
            />
          </div>
          {relatedArticles.length !== 0 && (
            <Fragment>
              <SectionTitle>Related Articles</SectionTitle>
              <RelatedArticles blogs={relatedArticles} className="mb-36" />
            </Fragment>
          )}
          <DownloadAppSection />
        </div>
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query GetBlogPost($slug: String!, $category: [String!]) {
    datoCmsBlog(slug: { eq: $slug }) {
      title
      slug
      timeToRead
      meta {
        createdAt(formatString: "Do MMMM YY")
      }
      heroImage {
        gatsbyImageData(imgixParams: { maxW: 100, maxH: 100 })
        alt
      }
      body {
        value
        links
        blocks {
          __typename
          ... on DatoCmsInlineImageBlock {
            id: originalId
            image {
              gatsbyImageData
              alt
            }
          }
        }
      }
      author {
        name
        slug
        twitterHandle
      }
      seo {
        title
        description
        image {
          url
        }
        twitterCard
      }
      noIndex
    }
    allDatoCmsBlog(
      sort: { order: DESC, fields: meta___createdAt }
      filter: {
        category: { elemMatch: { slug: { in: $category } } }
        slug: { ne: $slug }
      }
      limit: 3
    ) {
      nodes {
        title
        slug
        category {
          name
          slug
        }
        heroImage {
          gatsbyImageData
          alt
        }
      }
    }
  }
`

export default BlogPostTemplate
