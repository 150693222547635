import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { ToastContainer, toast } from "react-toastify"

const ShareArticle = ({ className, slug }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  const url = `${site.siteMetadata.siteUrl}/blog/${slug}`

  const notify = () => toast.success("Link copied to clipboard.")

  return (
    <Fragment>
      <div
        className={`${className} flex max-w-[293px] border-t-2 border-gray-4 pt-5`}
      >
        {/* Link */}
        <CopyToClipboard text={url}>
          <button
            className="group mr-10 h-[40px] w-[40px] !p-1"
            onClick={notify}
          >
            <svg
              fill="none"
              viewBox="0 0 24 24"
              className="group-hover:svg-gradient stroke-current text-gray-3"
            >
              <defs>
                <linearGradient
                  id="gradient"
                  gradientUnits="userSpaceOnUse"
                  fy="90%"
                >
                  <stop offset="0" style={{ stopColor: "#FFA903" }} />
                  <stop offset="1" style={{ stopColor: "#FC4706" }} />
                </linearGradient>
              </defs>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 13a5.001 5.001 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M14 11a5.001 5.001 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              />
            </svg>
          </button>
        </CopyToClipboard>
        {/* Facebook */}
        <FacebookShareButton
          url={url}
          className="group mr-10 h-[40px] w-[40px] !p-1"
        >
          <svg
            fill="none"
            viewBox="0 0 24 24"
            className="group-hover:svg-gradient stroke-current text-gray-3"
          >
            <defs>
              <linearGradient
                id="gradient"
                gradientUnits="userSpaceOnUse"
                fy="90%"
              >
                <stop offset="0" style={{ stopColor: "#FFA903" }} />
                <stop offset="1" style={{ stopColor: "#FC4706" }} />
              </linearGradient>
            </defs>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3V2Z"
            />
          </svg>
        </FacebookShareButton>
        {/* Twitter */}
        <TwitterShareButton
          url={url}
          className="group mr-10 h-[40px] w-[40px] !p-1"
        >
          <svg
            fill="none"
            viewBox="0 0 24 24"
            className="group-hover:svg-gradient stroke-current text-gray-3"
          >
            <defs>
              <linearGradient
                id="gradient"
                gradientUnits="userSpaceOnUse"
                fy="90%"
              >
                <stop offset="0" style={{ stopColor: "#FFA903" }} />
                <stop offset="1" style={{ stopColor: "#FC4706" }} />
              </linearGradient>
            </defs>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5 0-.279-.028-.556-.08-.83A7.72 7.72 0 0 0 23 3v0Z"
            />
          </svg>
        </TwitterShareButton>
        {/* LinkedIn */}
        <LinkedinShareButton
          url={url}
          className="group mr-10 h-[40px] w-[40px] !p-1"
        >
          <svg
            fill="none"
            viewBox="0 0 24 24"
            className="group-hover:svg-gradient stroke-current text-gray-3"
          >
            <defs>
              <linearGradient
                id="gradient"
                gradientUnits="userSpaceOnUse"
                fy="90%"
              >
                <stop offset="0" style={{ stopColor: "#FFA903" }} />
                <stop offset="1" style={{ stopColor: "#FC4706" }} />
              </linearGradient>
            </defs>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-4 0v7h-4v-7a6 6 0 0 1 6-6v0ZM6 9H2v12h4V9ZM4 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
            />
          </svg>
        </LinkedinShareButton>
      </div>
      <ToastContainer />
    </Fragment>
  )
}

export default ShareArticle
