import React from "react"
import MasonryLayoutWrapper from "../MasonryLayoutWrapper"

import { BlogCard } from "../UI"

const RelatedArticles = ({ blogs, className }) => {
  return (
    <section className={`${className}`}>
      <MasonryLayoutWrapper>
        {React.Children.toArray(
          blogs.map(b => (
            <BlogCard
              title={b.title}
              slug={b.slug}
              image={b.heroImage}
              category={b.category}
            />
          ))
        )}
      </MasonryLayoutWrapper>
    </section>
  )
}

export default RelatedArticles
