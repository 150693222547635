import React from "react"
import { Link } from "gatsby"

import { Dot } from "../UI"
// import { capitalizeWord } from "../../utils"

const AuthorBox = ({ meta, author, className, title, timeToRead }) => {
  return (
    <div
      className={`${className} font-spartan text-base text-gray-2 lg:mr-10 `}
    >
      <div className="sticky top-32 left-0 transition-all duration-150">
        <h1 className="mb-6 text-4xl font-bold leading-[58px] text-black">
          {/* {capitalizeWord(title)} */}
          {title}
        </h1>
        <div className="mb-2 flex items-center">
          <span className="mr-[14px]">{meta.createdAt}</span>
          <span className="mr-[14px]">
            <Dot />
          </span>
          <span>{timeToRead} mins</span>
        </div>
        <Link to={`/author/${author.slug}/`} className="text-xs">
          By {author.name}
        </Link>
      </div>
    </div>
  )
}

export default AuthorBox
